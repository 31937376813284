import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export default ({ data }) => (
  <Layout current="contact">
    <h1>
      I'd love to talk! Email me at the address below
      {data.site.siteMetadata.title}
    </h1>
    <p>
      <a href="mailto:me@example.com">me@example.com</a>
    </p>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
